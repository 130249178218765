import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/outPlatform/outCameraEventGetCameraList",
        method: "post",
        params: params,
    });
};

export const queryRegionByCode = (params) => {
    return axios({
        url: "/api/outPlatform/queryRegionByCode",
        method: "post",
        params: params,
    });
};

export const addData = (params) => {
    return axios({
        url: "/api/outPlatform/addOutCamera",
        method: "post",
        data: params
    });
};

export const addList = (params) => {
    return axios({
        url: "/api/outPlatform/addOutCameraList",
        method: "post",
        data: params
    });
};

export const codeByCheck = (params) => {
    return axios({
        url: "/api/outPlatform/getOutCameraCodeByCheck",
        method: "post",
        data: params
    });
};


