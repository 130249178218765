<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="摄像头名称">
            <el-input v-model="searchForm.deviceName" placeholder="请输入 摄像头名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="唯一编号">
            <el-input v-model="searchForm.deviceCode" placeholder="请输入 唯一编号"></el-input>
          </el-form-item>
        </el-col>

      </template>
      <template #BTN>
        <el-button @click="pl" class="searchBtn">批量录入</el-button>
      </template>
    </Searchbar>

    <basicTable
        @selectChange="selectList = $event"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData.slice(page.current == 1 ? 0 : page.current * 10 - 10, page.current * 10)"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属区域" prop="area" :rules="[{ required: true, message: '所属区域 不能为空' }]">
              <selectTree
                  v-if="isTree"
                  @sclear="form.area = ''"
                  :treeData="treeData"
                  @selectTreeChange="form.area = $event"
                  :propsId="propsId"
              ></selectTree>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>
<script>
import { dataList, addData, queryRegionByCode, addList, codeByCheck } from "@/api/outside/camera";
import Searchbar from '@/components/Searchbar';
import selectTree from "@/components/selectTree";
import { getRegionById } from "@/api/system/region";
import { ElMessage, ElMessageBox } from "element-plus";
import { changeTime } from "../../util/date";
export default {
  components:{
    Searchbar,
    selectTree,
  },
  data(){
    return{
      checkedList:[],
      selectList: [],
      isTree: true,
      propsId:"",
      dialogFormVisible: false,
      title: '新增',
      form:{},
      treeData:[],
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "addressName",
          label: "安装地址",
          render: (row) => <div>{row.areas.addressName}</div>,
        },
        {
          prop: "deviceName",
          label: "摄像头名称",
          render: (row) => <div>{row.deviceName}</div>,
        },
        {
          prop: "address",
          label: "监控地址",
          render: (row) => <div>{row.address}</div>,
        },
        {
          prop: "longitude",
          label: "经度",
          render: (row) => <div>{row.areas.longitude}</div>,
        },
        {
          prop: "latitude",
          label: "纬度",
          render: (row) => <div>{row.areas.latitude}</div>,
        },
        {
          prop: "deviceCode",
          label: "唯一编号",
          render: (row) => <div>{row.deviceCode}</div>,
        },
        {
          prop: "createTime",
          label: "创建时间",
          render: (row) => <div>{changeTime(row.createTime / 1000)}</div>,
        },
        {
          prop: "updateTime",
          label: "修改时间",
          render: (row) => <div>{changeTime(row.updateTime / 1000)}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            if(!this.checkedList.includes(row.deviceCode)){
              return <el-button type="text" icon="CirclePlus" onClick={() => this.onAdd(row)}>录入设备</el-button>
            }else{
              return <div className="checked">已录入</div>
            }
          }
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  mounted() {

  },
  watch:{
  },
  created() {
    getRegionById().then(res=> this.treeData = res.data.data)
    this.onLoad();
  },
  methods:{
    pl(){
      if(!this.selectList.length) return this.$message({message: '至少选择一条或多条数据', type: 'warning'});
      let op = this.selectList.map(item=>{
        let { areas, deviceCode, address, deviceName } = item;
        return{
          location: `${areas.longitude},${areas.latitude}`,
          outDeviceCode: deviceCode,
          serialtwo: address,
          cname: deviceName,
        }
      })
      addList(op).then(res=>{
        this.$message.success("操作成功");
      })
    },

    handleClose(done){
      this.propsId = this.form.area = "";
      this.$refs.dataform.resetFields();
      done();
    },

    onSave(){
      this.$refs['dataform'].validate(async (valid) => {
        let { areas, deviceCode, address, deviceName, area } = this.form;
        if (valid) {
          addData({
            location: `${areas.longitude},${areas.latitude}`,
            outDeviceCode: deviceCode,
            serialtwo: address,
            cname: deviceName,
            area
          }).then(res=>{
            this.dialogFormVisible = false;
            this.$message.success("操作成功");
            this.propsId = this.form.area = "";
          })
        } else {
          return false;
        }
      });
    },

    onAdd(row){
      queryRegionByCode( { regionCode: row.areas.street } ).then(res=>{
        this.dialogFormVisible = true;
        this.form = JSON.parse(JSON.stringify(row));
        this.propsId = this.form.area = res.data.data.id;
      })
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      let arr = this.tableData.slice(this.page.current == 1 ? 0 : this.page.current * 10 - 10, this.page.current * 10);
      let data = arr.map(item=>item.deviceCode);
      codeByCheck(data).then(res=>{
        this.checkedList = res.data.data;
      })
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
    },

    onSearch(){
      this.page.current = 1;

      if(!this.searchForm.deviceName && !this.searchForm.deviceCode) return

      if(this.searchForm.deviceName && this.searchForm.deviceCode){
        this.tableData = this.notData.filter(item=>item.deviceName.indexOf(this.searchForm.deviceName) != -1 && item.deviceCode.indexOf(this.searchForm.deviceCode) != -1 );
      }else{
        this.tableData = this.notData.filter(item=>item.deviceName.indexOf(this.searchForm.deviceName) != -1 || item.deviceCode.indexOf(this.searchForm.deviceCode) != -1 );
      }
    },

    resetForm() {
      this.page.current = 1;
      this.searchForm = {};
      this.tableData = this.notData;
    },


    onLoad(){
      dataList({ ...this.searchForm }).then(res=>{
        this.tableData = this.notData = res.data.data;
        this.page.total = res.data.data.length;
        let data = res.data.data.map(item=>item.deviceCode);
        codeByCheck(data).then(res=>{
          this.checkedList = res.data.data;
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .checked{
  color: #afafaf;
  font-size: 14px;
}
.tab{
  width: 100%;
  display: flex;
  flex-direction: column;
  >article{
    font-size: 20px;
    //padding: 0 50px;
    border: 1px solid;
    border-bottom: none;
    display: flex;
    align-items: center;
    &:last-child{
      border-bottom: 1px solid;
    }
    section{
      padding: 10px;
      &:nth-child(1){
        width: 140px;
        text-align: center;
      }
      &:nth-child(2){
        border-left: 1px solid;
        width: calc(100% - 140px);
        text-align: left;
      }
    }
  }
}
</style>